<template>
    <v-app>
        <v-main>
            <v-card
                    color="green"
                    class="app-header"
            >
                <v-container>
                    <v-btn small
                           :loading="importRunning"
                           @click="runImport"
                           class="mr-1"
                    >
                        ПРОВЕСТИ ОБМЕН
                    </v-btn>
                    <v-btn small
                           class="mr-1"
                           color="primary"
                    >
                        Закрыть период
                    </v-btn>
                </v-container>
            </v-card>
            <v-progress-linear indeterminate
                               v-show="importRunning"
            ></v-progress-linear>
            <v-data-table
                    :headers="dealHeaders"
                    :items="deals"
                    item-key="b24_id"
                    sort-by="total"
                    group-by="company.b24_id"
                    :sort-desc="true"
                    class="elevation-1"
            >
                <template v-slot:item.company="{item}">
                    <td class="app-table__company">
                        <a :href="'/crm/company/details/' + item.company ? item.company.b24_id : -1"
                           @click.prevent="openPath('company', item.company ? item.company.b24_id : -1)"
                        >
                            {{item.company ? item.company.title : "Без компании"}}
                        </a>
                    </td>
                </template>
                <template v-slot:item.client="{item}">
                    <td class="app-table__company">
                        <a :href="'/crm/company/details/' + item.client ? item.client.b24_id : -1"
                           @click.prevent="openPath('company', item.client ? item.client.b24_id : -1)"
                        >
                            {{item.client ? item.client.title : "Без компании"}}
                        </a>
                    </td>
                </template>
                <template v-slot:item.title="{item}">
                    <a :href="'/crm/deal/details/' + item.b24_id"
                       @click.prevent="openPath('deal', item.b24_id)"
                    >
                        {{item.title}}
                    </a>
                </template>
                <template v-slot:group.header="{items, headers, isOpen, toggle}">
                    <td :colspan="headers.length">
                        <div class="app-table-group">
                            <div class="app-table-group__info">
                                <v-btn :class="{'--arrow-active': isOpen,}"
                                       icon
                                       @click="toggle"
                                >
                                    <v-icon>
                                        {{icons.mdiChevronDown}}
                                    </v-icon>
                                </v-btn>
                                <a :href="'/crm/company/details/' + items[0].company ? items[0].company.b24_id : -1"
                                   @click.prevent="openPath('company', items[0].company ? items[0].company.b24_id : -1)"
                                >
                                    {{items[0].company ? items[0].company.title: "Без компании"}}
                                </a>
                            </div>
                            <v-btn small
                                   class="mr-1"
                                   color="primary"

                                   @click="generateDocument(items[0].company.b24_id)"
                            >
                                Закрыть период
                            </v-btn>
                        </div>
                    </td>
                    <td :colspan="headers.length - 1"></td>
                </template>
            </v-data-table>
            <v-dialog v-model="generateDialog.show"
                      hide-overlay
                      :persistent="generateDialog.loading"
                      width="400"
            >
                <v-card :color="generateDialog.loading ? 'primary' : ''"
                        :dark="generateDialog.loading"
                >
                    <v-card-text v-if="generateDialog.loading">
                        Идет составление отчета
                        <v-progress-linear indeterminate
                                           color="white"
                                           class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                    <v-card-text v-else
                                 class="pa-5"
                    >
                        <a :href="generateDialog.link" target="_blank"
                           class="text-h6" style="width: 100%;display: block;text-align: center;"
                        >
                            Ссылка на отчет
                        </a>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-main>
    </v-app>
</template>

<script>
    import {mdiChevronDown} from '@mdi/js';

    export default {
        name: 'App',

        data: () => ({
            deals: [],
            generateDialog: {
                show: false,
                loading: true,
                link: "",
            },
            dealHeaders: [
                {text: "Компания", value: 'company'},
                {text: "Клиент", value: 'client'},
                {text: "Название VM", value: 'title'},
                {text: "OS", value: 'os'},
                {text: "CPU", value: "cpu"},
                {text: "RAM", value: "memory"},
                {text: "HDD SATA", value: "sata_disk"},
                {text: "HDD SAS", value: "sas_disk"},
                {text: "SSD", value: "ssd_disk"},
                {text: "Лицензии", value: 'os'},
                {text: "IP-адрес", value: 'ip'},
                {text: "Доступ в интернет", value: 'ethernet'},
                {text: "Резервное копирование", value: 'backup'},
                {text: "Тарифный план", value: 'tariff'},
                {text: "Стоимость", value: "total"},
            ],
            importRunning: false,
            icons: {
                mdiChevronDown,
            }
        }),
        methods: {
            async get_deals() {
                let response = await fetch(
                    'https://b24-app-virtualizer.drv6.place-start.ru/api/data/',
                    {method: "POST"}
                )
                this.deals = await response.json()
            },
            openPath(entity, id) {
                console.log('IN')
                this.$BX24.openPath(`/crm/${entity}/details/${id}/`, function (result) {
                    console.log(result)
                })
            },
            async runImport() {
                this.importRunning = true;
                let response = await fetch('https://b24-app-virtualizer.drv6.place-start.ru/api/run_import/',
                    {method: "POST"});
                this.deals = await response.json();
                this.importRunning = false;
            },
            async generateDocument(companyId) {
                this.generateDialog.show = true;
                this.generateDialog.loading = true;
                this.generateDialog.link = "";
                let fd = new FormData()
                fd.append('company', companyId)
                let response = await fetch('https://b24-app-virtualizer.drv6.place-start.ru/api/document.generate/',
                    {method: "post", body: fd});
                response = await response.json();
                this.generateDialog.loading = false;
                this.generateDialog.link = "https://b24-app-virtualizer.drv6.place-start.ru/" + response.link
            }
        },
        watch: {
            init: {
                immediate: true,
                handler: function () {
                    this.get_deals()
                }
            }
        }
    };
</script>

<style lang="scss">
    #download_json {
        width: 400px;
        height: 400px;
        display: block;
        background: red;
    }

    .app-table {
        &-group {
            display: flex;
            align-items: center;
            min-width: 200px;

            &__info {
                flex: 1;
            }

            &.--arrow-active {
                transform: rotate(180deg);
            }

        }

        &__company {
            min-width: 200px;
        }
    }

    .app-header {
        margin-bottom: 30px;
    }

    .app-inner-t {
        margin: 20px 0;

        & > div {
            overflow-y: scroll;
            max-width: 100vw;
        }
    }
</style>