var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[_c('v-card',{staticClass:"app-header",attrs:{"color":"green"}},[_c('v-container',[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","loading":_vm.importRunning},on:{"click":_vm.runImport}},[_vm._v(" ПРОВЕСТИ ОБМЕН ")]),_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"primary"}},[_vm._v(" Закрыть период ")])],1)],1),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.importRunning),expression:"importRunning"}],attrs:{"indeterminate":""}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.dealHeaders,"items":_vm.deals,"item-key":"b24_id","sort-by":"total","group-by":"company.b24_id","sort-desc":true},scopedSlots:_vm._u([{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"app-table__company"},[_c('a',{attrs:{"href":'/crm/company/details/' + item.company ? item.company.b24_id : -1},on:{"click":function($event){$event.preventDefault();return _vm.openPath('company', item.company ? item.company.b24_id : -1)}}},[_vm._v(" "+_vm._s(item.company ? item.company.title : "Без компании")+" ")])])]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"app-table__company"},[_c('a',{attrs:{"href":'/crm/company/details/' + item.client ? item.client.b24_id : -1},on:{"click":function($event){$event.preventDefault();return _vm.openPath('company', item.client ? item.client.b24_id : -1)}}},[_vm._v(" "+_vm._s(item.client ? item.client.title : "Без компании")+" ")])])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'/crm/deal/details/' + item.b24_id},on:{"click":function($event){$event.preventDefault();return _vm.openPath('deal', item.b24_id)}}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"group.header",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"app-table-group"},[_c('div',{staticClass:"app-table-group__info"},[_c('v-btn',{class:{'--arrow-active': isOpen,},attrs:{"icon":""},on:{"click":toggle}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiChevronDown)+" ")])],1),_c('a',{attrs:{"href":'/crm/company/details/' + items[0].company ? items[0].company.b24_id : -1},on:{"click":function($event){$event.preventDefault();return _vm.openPath('company', items[0].company ? items[0].company.b24_id : -1)}}},[_vm._v(" "+_vm._s(items[0].company ? items[0].company.title: "Без компании")+" ")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.generateDocument(items[0].company.b24_id)}}},[_vm._v(" Закрыть период ")])],1)]),_c('td',{attrs:{"colspan":headers.length - 1}})]}}])}),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":_vm.generateDialog.loading,"width":"400"},model:{value:(_vm.generateDialog.show),callback:function ($$v) {_vm.$set(_vm.generateDialog, "show", $$v)},expression:"generateDialog.show"}},[_c('v-card',{attrs:{"color":_vm.generateDialog.loading ? 'primary' : '',"dark":_vm.generateDialog.loading}},[(_vm.generateDialog.loading)?_c('v-card-text',[_vm._v(" Идет составление отчета "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1):_c('v-card-text',{staticClass:"pa-5"},[_c('a',{staticClass:"text-h6",staticStyle:{"width":"100%","display":"block","text-align":"center"},attrs:{"href":_vm.generateDialog.link,"target":"_blank"}},[_vm._v(" Ссылка на отчет ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }